import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getResourceList } from '../../models/BaseModel'
import { ImageReplacementAnchor, FooterNavigation, SocialLinks, BlankErrorBoundary } from '@scrippsproduct/scripps-external-component-library'

import './../../scss/Footer.scss'

export default function Footer() {
    const [navSections, setNavSections] = useState([])
    const [socialLinks, setSocialLinks] = useState([])
    const {isSuccess, data} = useQuery(['footer', {resourceName: 'footer'}], getResourceList)

    useEffect(() => {
        if (isSuccess) {
            setNavSections(data.get('navSections'))
            setSocialLinks(data.get('socialLinks'))
        }
    }, [isSuccess, data])

    return (
        <footer className="main-footer">
            <content-constrainer>
                <div className="footer-logo">
                    <ImageReplacementAnchor href={'/'} 
                        image={{
                            url: 'https://ewscripps.brightspotcdn.com/4a/d1/4651099144b88a57c0e4d0692369/button-ion-plus-logo-2x.png', 
                            width: 250, 
                            height: 100
                        }}  
                        height={2.4}
                        baselineAdjustment={0}
                    />
                </div>
                <div className="footer-nav-wrapper">
                    { (isSuccess) && 
                        <BlankErrorBoundary>
                            <FooterNavigation sections={navSections} />
                        </BlankErrorBoundary>
                    }
                    { (isSuccess && socialLinks.length > 0) &&
                        <BlankErrorBoundary>
                            <SocialLinks size={1.5} showHeader={true} platforms={socialLinks}/>
                        </BlankErrorBoundary> 
                    }
                </div>
                <div className="copyright-notice">
                    {`© ${new Date().getFullYear()} ION Media Networks, all rights reserved. All third-party trademarks, including logos, are the property of their respective owners.`}
                </div>
            </content-constrainer>
        </footer>
    )
}
