import React from 'react'
import ReactDOM from 'react-dom/client'
import Site from './Site'
//import reportWebVitals from './reportWebVitals'
import { QueryClient,  QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import './scss/Settings.scss'
import './scss/Base.scss';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 900000, // 15 mins
        },
    }
  });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {/* <OptinMonsterScript /> */}
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <BrowserRouter>
                        <Site />
                    </BrowserRouter>
                </HelmetProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </React.StrictMode>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
